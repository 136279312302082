<template>
  <div class="container is-fluid">
    <page-header :entity="$EntitiesName.Import">
      <h1 class="title" data-test="sel-project-header">
        Import SAP
      </h1>
      <template #actions>
        <button v-if="!isImporting && entitiesToDelete.length > 0" class="button is-danger" @click="deleteEntities">Supprimer</button>
        <button v-if="!isImporting" class="button is-warning" @click="isImporting = !isImporting">Importer</button>
        <button v-if="isImporting" class="button is-warning" @click="isImporting = !isImporting">Annuler</button>
      </template>
    </page-header>

    <datatable v-if="!isImporting" :fetch="$Api.Import.fetchImportSap" ref="datatable" :entity="$EntitiesName.ImportSap" v-model="entitiesToDelete" enableDownload downloadEntity="import/sap">
      <div slot="purchaseDocument" slot-scope="{ item }" title="Document d'achat" :sortable="true">{{ item.purchaseDocument }}</div>
      <div slot="imputation" slot-scope="{ item }" title="Elément d'OTP" :sortable="true">{{ item.imputation && item.imputation.eotp }}</div>
      <div slot="partType" slot-scope="{ item }" title="Type de pièce" :sortable="true">{{ item.partType }}</div>
      <div slot="counterpartAccount" slot-scope="{ item }" title="Compte contrepartie" :sortable="true">{{ item.counterpartAccount }}</div>
      <div slot="itemDesignation" slot-scope="{ item }" title="Désignation de l'objet" :sortable="true">{{ item.itemDesignation }}</div>
      <div slot="tiersDesignation" slot-scope="{ item }" title="Désignation du Tiers" :sortable="true">{{ item.tiersDesignation }}</div>
      <div slot="period" slot-scope="{ item }" title="Période" :sortable="true">{{ item.period }}</div>
      <div slot="partNumber" slot-scope="{ item }" title="Nº pièce" :sortable="true">{{ item.partNumber }}</div>
      <div slot="lineNumber" slot-scope="{ item }" title="Ligne d'écriture" :sortable="true">{{ item.lineNumber }}</div>
      <div slot="accountingYear" slot-scope="{ item }" title="Exercice comptable" :sortable="true">{{ item.accountingYear }}</div>
      <div slot="amount" slot-scope="{ item }" title="Val./devise périm." class="has-text-right" :sortable="true">{{ item.amount | priceEUR}}</div>
      <div slot="designation" slot-scope="{ item }" title="Désignation" :sortable="true">{{ item.designation }}</div>
      <div slot="user" slot-scope="{ item }" title="Utilisateur" :sortable="true">{{ item.user }}</div>
      <div slot="accountingNature" slot-scope="{ item }" title="Nature comptable" :sortable="true">{{ item.accountingNature && item.accountingNature.name }}</div>
      <div slot="partHeader" slot-scope="{ item }" title="Texte d'en-tête de pièce" :sortable="true">{{ item.partHeader }}</div>
      <div slot="accountingDate" slot-scope="{ item }" title="Date comptable" :sortable="true">{{ item.accountingDate }}</div>
      <div slot="accountingNatureDescription" slot-scope="{ item }" title="Descr.nat.comptable" :sortable="true">{{ item.accountingNatureDescription }}</div>
    </datatable>
    
    <div v-if="isImporting" class="panel infos">
      <div class="panel-heading has-background-primary">Copier/Coller pour importer</div>
      <div class="panel-block is-block no-border">
        <handsontable :columns="columns" :import="importSap"/>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { isNumeric } from '../../lib/data/Validation'
export default {
  name: 'sap_import',
  data: function() {
    return {
      isImporting: false,
      entitiesToDelete: [],
      columns: [
        {
          key: 'purchaseDocument',
          label: 'Document d\'achat',
        },
        {
          key: 'eotp',
          label: 'Elément d\'OTP',
          required: true
        },
        {
          key: 'partType',
          label: 'Type de pièce',
        },
        {
          key: 'counterpartAccount',
          label: 'Compte contrepartie',
        },
        {
          key: 'itemDesignation',
          label: 'Désignation de l\'objet',
          required: true,
        },
        {
          key: 'tiersDesignation',
          label: 'Désignation du Tiers',
        },
        {
          key: 'period',
          label: 'Période',
          required: true,
          validator: function(value) {
            return !value || (value >= 1 && value <= 12)
          }
        },
        {
          key: 'partNumber',
          label: 'Nº pièce',
          required: true
        },
        {
          key: 'lineNumber',
          label: 'Ligne d\'écriture',
          required: true
        },
        {
          key: 'accountingYear',
          label: 'Exercice comptable',
          required: true
        },
        {
          key: 'amount',
          label: 'Val./devise périm.',
          required: true,
          validator: (value) => isNumeric(value)
        },
        {
          key: 'designation',
          label: 'Désignation',
        },
        {
          key: 'user',
          label: 'Utilisateur',
          required: true,
        },
        {
          key: 'accountingNature',
          label: 'Nature comptable',
          required: true
        },
        {
          key: 'partHeader',
          label: 'Texte d\'en-tête de pièce',
        },
        {
          key: 'accountingDate',
          label: 'Date comptable',
          required: true,
        },
        {
          key: 'accountingNatureDescription',
          label: 'Descr.nat.comptable',
          required: true
        }
      ]
    };
  },
  computed: {
    selectedToUrl() {
      return this.entitiesToDelete.map(object => object.id).toString();
    },
  },
  methods: {
    importSap(data) {
      return this.$Api.Import.importSap(data);
    },
    deleteEntities() {
      return this.$awn.confirm(
        "Êtes-vous sûr de vouloir supprimer ces éléments ?",
        () =>
          axios.delete(`/import/sap?selected=${this.selectedToUrl}`).then((res) => {
            if (res.data.success) {
              this.$refs.datatable.refresh()
              this.entitiesToDelete = []
            }
          })
      );
    },
  }
}
</script>
<style>

</style>
